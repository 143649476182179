export function getSessionStorage(key, defaultValue) {
    try {
        return JSON.parse(window.sessionStorage.getItem(key))? JSON.parse(window.sessionStorage.getItem(key)) : defaultValue
    } catch (e) {
        if (defaultValue) {
            return defaultValue
        }
        return window.sessionStorage.getItem(key)
    }
}
export  function setSessionStorage(key, data) {
  try {
    if(['string', 'number'].includes(typeof(data))){
      return window.sessionStorage.setItem(key, data)
    }
    return window.sessionStorage.setItem(key, JSON.stringify(data))
  } catch (e) {
    console.log('err', e)
    return window.sessionStorage.setItem(key, data)
  }
}
