import { axios } from '@/utils/request'

const api = {
  Login: "/TokenAuth/Authenticate",
  GetUserProducts: "/TokenAuth/GetUserProducts",
  GetLoginInfor: "/services/app/Session/GetCurrentLoginInformations",
  fetchSchoolName: "/services/app/Tenant/GetTenantName",
  GetSimulation: '/services/app/EnglishSimulation/GetSimulation',
  // PasswordLogin: '/TokenAuth/Authenticate',
  GetExamQuestion: '/services/app/EnglishListening/GetExamQuestion',
  SaveAnswer: '/services/app/EnglishListening/SaveAnswer',
  GetSimulationSections: '/services/app/EnglishSimulation/GetSimulationSections',
  GetSimulationSectionQuestions: '/services/app/EnglishSimulation/GetSimulationSectionQuestions',
  SaveSimulation: '/services/app/EnglishSimulation/SaveSimulation',
  GetStudentEnglishAnswerPicture: '/services/app/EnglishSimulation/GetStudentEnglishAnswerPicture',
  GetSimulationExamRecord: '/services/app/EnglishSimulation/GetSimulationExamRecord', // 模拟试卷测试结果首页
  GetEngFinishProgress: '/services/app/EnglishSimulation/GetEngFinishProgress',
  GetEngSimulationReport: '/services/app/EnglishReport/GetEngSimulationReport', //大模拟诊断报告
  GetSimulationFreeTraining: '/services/app/EnglishSimulation/GetSimulationFreeTraining',
  PdfSimulationReport: "/services/app/EnglishReport/PdfSimulationReport"
}

export function fetchSchoolName(parameter) {
  return axios({
    url: api.fetchSchoolName,
    method: "GET",
    params: parameter,
  });
}

export function login(parameter) {
  return axios({
    url: api.Login,
    method: "post",
    data: parameter,
    loading: false
  });
}

export function getUserProducts(parameter) {
  return axios({
    url: api.GetUserProducts,
    method: "GET",
    params: parameter,
    loading: false
  });
}

export function getLoginInfor(parameter) {
  return axios({
    url: api.GetLoginInfor,
    method: "get",
    params: parameter,
    loading: false
  });
}

export function getSimulation(parameter) {
  return axios({
    url: api.GetSimulation,
    method: 'get',
    params: parameter
  })
}

export function passwordLogin(parameter) {
  return axios({
    url: api.PasswordLogin,
    method: 'post',
    data: parameter
  })
}
export function getExamQuestion(parameter) {
  return axios({
    url: api.GetExamQuestion,
    method: 'get',
    params: parameter
  })
}

export function saveAnswer(parameter) {
  return axios({
    url: api.SaveAnswer,
    method: 'post',
    data: parameter
  })
}

export function getSimulationSections(parameter) {
  return axios({
    url: api.GetSimulationSections,
    method: 'get',
    params: parameter
  })
}

export function getSimulationSectionQuestions(parameter) {
  return axios({
    url: api.GetSimulationSectionQuestions,
    method: 'get',
    params: parameter
  })
}

export function saveSimulation(parameter) {
  return axios({
    url: api.SaveSimulation,
    method: 'post',
    data: parameter
  })
}

export function getStudentEnglishAnswerPicture(parameter) {
  return axios({
    url: api.GetStudentEnglishAnswerPicture,
    method: 'get',
    params: parameter,
  })
}

export function getSimulationExamRecord(parameter) {
  return axios({
    url: api.GetSimulationExamRecord,
    method: 'get',
    params: parameter
  })
}

export function getEngFinishProgress(parameter) {
  return axios({
    url: api.GetEngFinishProgress,
    method: "get",
    params: parameter,
  });
}

export function getEngSimulationReport(parameter) {
  return axios({
    url: api.GetEngSimulationReport,
    method: 'get',
    params: parameter
  })
}

//大模拟自由训练列表页
export function getSimulationFreeTraining(parameter) {
  return axios({
    url: api.GetSimulationFreeTraining,
    method: 'get',
    params: parameter
  })
}

// 大模拟 pdf下载 
export function pdfSimulationReport(parameter) {
  return axios({
    url: api.PdfSimulationReport,
    method: "post",
    params: parameter,
  });
}