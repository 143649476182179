import axios from 'axios'
import Vue from 'vue'
import { Notification, MessageBox } from "element-ui";
import router from "../router";
const _serveBaseURL = process.env.VUE_APP_API_BASE_URL
export const serveBaseURL = _serveBaseURL
axios.defaults.withCredentials = true
const service = axios.create({
    baseURL: getBaseurl(), // api base_url
    timeout: 100000, // 请求超时时间
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    responseType: 'json'
})

function getBaseurl() {
    console.log('process.env.NODE_ENV', process.env.NODE_ENV)
    if (process.env.NODE_ENV === 'development') {
        return '/api1'
    } else if (process.env.NODE_ENV === 'debug') {
        return '/api'
    } else {
        return _serveBaseURL + '/api'
    }
}
const err = (error) => {
    console.log('错误内容', error.response)
    if (error.response) {
        switch (error.response.status) {
            case 404:
                Notification.error({
                    title: "网络请求不存在，错误代码404",
                    message: "请联系管理员处理"
                });
                break;
            case 500:
                if (error.response.data.error.code == 20001) {
                    Notification.error({
                        title: "错误代码 20001",
                        message: "请关闭多余网页，不要重复提交！"
                    });
                    router.push("/ms3sprint/home/homeIndex");
                } else {
                    Notification.error({
                        title: "网络请求失败，错误代码500",
                        message: "请联系管理员处理"
                    });
                }

                break;
            case 403:
                Notification.error({
                    title: "权限错误，错误代码403",
                    message: "没有访问权限，请联系管理员处理"
                });
                router.push({
                    name: 'HomeIndex'
                });
                break;
            case 401:
                if (router.currentRoute.path == "/login") {
                    return;
                }
                MessageBox.alert("检测到您的登录已失效，请重新登录", "温馨提示", {
                    confirmButtonText: "确定",
                    callback: () => {
                        sessionStorage.clear();
                        window.location.href = "https://peiliyou.com";
                    }
                });
                break;
            default:

                break;
        }
    }
}
JSON.stringifyIfObject = function stringifyIfObject(obj) {
    if (typeof obj === 'object') return JSON.stringify(obj)
    else {
        console.log('found already stringified object')
        return obj
    }
}
service.interceptors.request.use(config => {
    console.log('请求config', config)
    if (!config.url) {
        return
    }

    const token = window.sessionStorage.getItem("userToken");
    if (token && config.url !== '/TokenAuth/Authenticate') {
        // 登录的时候不需要token
        config.headers.Authorization = 'Bearer ' + token // 让每个请求携带自定义 token 请根据实际情况自行修改
    } else {
        config.withCredentials = false
    }

    // prevent http 400 error. stringify data if data are not stringified before submit to api
    if (config.data) {
        config.data = JSON.stringifyIfObject(config.data)
    }


    return config
}, err)
service.interceptors.response.use(response => {



    return response;
}, err);

Vue.use(service)

export { service as axios }