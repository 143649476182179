const getters = {
  listOfWrongQuestions: state => state.listOfWrongQuestions,
  redoQuestion: state => state.redoQuestion,
  examId: state => state.examId,
  currentModuleId: state => state.currentModuleId,
  userAnswers: state => state.userAnswers,
  userId: state => state.userId,
  userInfo: state => state.userInfo,
  logDetail: state => state.logDetail
}
export default getters
