import { axios } from '@/utils/request'

const api = {
  GetEngCurrentModule: "/services/app/EnglishSprint/GetEngCurrentModule",
  GetWelcome: '/services/app/EnglishCommon/GetWelcome',
  ChangePassword: "/services/app/Profile/ChangePassword",
  // GetEngSprintProgress:"/services/app/EnglishSprint/GetEngSprintProgress",
  GetRewardsRank: "/services/app/EnglishSprint/GetRewardsRank",
  GetRewardsOverviewForView: "/services/app/EngRewards/GetRewardsOverviewForView",
  GetWeeksRewards: "/services/app/EngRewards/GetWeeksRewards",
  GetNotification:"/services/app/EnglishSprint/GetNotification",
  SaveThumb: '/services/app/EnglishSprint/SaveThumb'
}

export function getNotification(parameter) {
  return axios({
    url: api.GetNotification,
    method: "GET",
    params: parameter,
  });
}
export function getEngCurrentModule(parameter) {
  return axios({
    url: api.GetEngCurrentModule,
    method: "GET",
    params: parameter,
  });
}

export function getWelcome(parameter) {
  return axios({
    url: api.GetWelcome,
    method: "GET",
    params: parameter,
  });
}

export function ChangePassword(parameter) {
  //   moduleStageId 如果传了这个参数，moduleProgressId与stage的参数会被忽略。 moduleStageId从startStage result.id获得，如果没有这个参数，按第二个办法传参
  // moduleProgressId与stage
  return axios({
    url: api.ChangePassword,
    method: "post",
    data: parameter,
    messagebox: false,
    loading: false
  });
}

// 闯关进度
// export function getEngSprintProgress(parameter) {
//   return axios({
//     url: api.GetEngSprintProgress,
//     method: "GET",
//     params: parameter,
//   });
// }

//本周榜单
export function getRewardsRank(parameter) {
  return axios({
    url: api.GetRewardsRank,
    method: "GET",
    params: parameter,
  });
}

//培优币列表
export function getRewardsOverviewForView(parameter) {
  return axios({
    url: api.GetRewardsOverviewForView,
    method: "GET",
    params: parameter,
  });
}


//过去十周培优币
export function getWeeksRewards(parameter) {
  return axios({
    url: api.GetWeeksRewards,
    method: "GET",
    params: parameter,
  });
}

// 闯关成功学生点赞
export function saveThumb(parameter) {
  return axios({
    url: api.SaveThumb,
    method: "post",
    data: parameter,
  });
}