import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)


import HomeLayout from "../layout/home-layout/home-layout.vue";
import keepView from "../layout/keepView";
const routes = [
  //登录路由
  {
    path: '/',
    name: 'LoginLayout',
    redirect: '/login',
    component: () => import('../layout/login-register/login-register-layout.vue'),
    children: [
      //登录页
      { 
        path: '/login',
        name: 'Login',
        component: () => import('../views/english-login/login-register.vue'),
      },
      //首页
      {
        path: '/ms3sprint/home',
        name: 'Ms3sprintHome',
        redirect: '/ms3sprint/home/homeIndex',
        component: HomeLayout,
        children: [
          //闯关地图
          {
            path: 'homeIndex',
            name: 'HomeIndex',
            component: () => import('../views/Home.vue'),
            meta: { yesHome: true }
          },
          //战备体检
          {
            path: 'examination',
            name: 'Examination',
            redirect: 'examination/test',
            component: () => import('@/views/english-examination/index.vue'),
            children: [
              {
                path: 'test',
                name: 'ExaminationTest',
                component: () => import('@/views/english-examination/english-examination-test.vue'),
                meta: { startImg: true }
              },
              {
                path: 'end',
                name: 'ExaminationEnd',
                component: () => import('@/views/english-examination/english-examination-end.vue'),
                meta: { startImg: true }
              },
              {
                path: 'report',
                name: 'ExaminationReport',
                component: () => import('@/views/english-examination/english-examination-report.vue'),
                meta: { startReportImg: true }
              },

            ]
          },
          // 小模块
          {
            path: 'small',
            name: 'Small',
            redirect: 'small/test',
            component: () => import('../views/english-small/index.vue'),
            children: [
              {
                path: 'test',
                name: 'SmallModuleTest',
                component: () => import('@/views/english-small/english-small-test.vue'),
                meta: { smallImg: true }
              },
              {
                path: 'grammar-list',
                name: 'SmallModuleGrammarList',
                component: () => import('@/views/english-small/grammar-directory.vue'),
                meta: { smallImg: true }
              },
              {
                path: 'end',
                name: 'SmallModuleTestEnd',
                component: () => import('@/views/english-small/english-small-test-end.vue'),
                meta: { smallImg: true }
              },
              {
                path: 'report',
                name: 'SmallModuleReport',
                component: () => import('@/views/english-small/english-small-report.vue'),
                meta: { smallReportImg: true }
              },
              {
                path: 'grammar-end',
                name: 'SmallModuleGrammarTestEnd',
                component: () => import('@/views/english-small/grammar-sprint-test-end.vue'),
                meta: { smallImg: true }
              },
              {
                path: 'word-list',
                name: 'SmallModuleWordList',
                component: () => import('@/views/english-small/word-directory.vue'),
                meta: { smallImg: true }
              },
              {
                path: 'grammar-sprint-test',
                name: 'SmallModuleGrammarSprintTest',
                component: () => import('@/views/english-small/grammar-sprint-test.vue'),
                meta: { smallImg: true }
              },
              {
                path: 'sprint-test',
                name: 'SmallModuleSprintTest', // 小模块训练
                component: () => import('@/views/english-small/sprint-test.vue'),
                meta: { smallImg: true }
              },
              {
                path: 'results-test',
                name: 'SmallModuleResultsTest',
                component: () => import('@/views/english-small/results-test.vue'),
                meta: { smallImg: true }
              },
              {
                path: 'results-test-end',
                name: 'SmallModuleResultsTestEnd',
                component: () => import('@/views/english-small/results-test-end.vue'),
                meta: { smallImg: true }
              },
              {
                path: 'training-questions-results',
                name: 'SmallModuleTrainingQuestionsResults',
                component: () => import('../views/english-small/english-small-training-questions-results.vue'),
                meta: { smallImg: true }
              },
              {
                path: 'start-effect-evaluation',
                name: 'ModuleStartEffectEvaluation',
                component: () => import('../views/english-small/english-small-start-effect-evaluation.vue'),
                meta: { smallImg: true }
              },
              {
                path: 'free-list',
                name: 'SmallModuleFreeList',
                component: () => import('@/views/english-small/free-small-directory.vue'),
                meta: { smallImg: true }
              },
            ]
          },
          // 大模块
          {
            path: 'big',
            name: 'Big',
            redirect: 'big/test',
            component: () => import('../views/english-big/index.vue'),
            children: [
              {
                path: 'test',
                name: 'BigModuleTest',
                component: () => import('@/views/english-big/english-big-test.vue'),
                meta: { bigImg: true }
              },
              {
                path: 'end',
                name: 'BigModuleTestEnd',
                component: () => import('@/views/english-big/english-big-test-end.vue'),
                meta: { bigImg: true }
              },
              {
                path: 'report',
                name: 'BigModuleReport',
                component: () => import('@/views/english-big/english-big-report.vue'),
                meta: { bigReportImg: true }
              },
              {
                path: 'training-base',
                name: 'BigModuleTrainingBase',
                component: () => import('@/views/english-big/english-big-training-base.vue'),
                meta: { bigImg: true }
              },
              {
                path: 'free-base',
                name: 'BigModuleTrainingFreeBase',
                component: () => import('@/views/english-big/english-big-free-base.vue'),
                meta: { bigImg: true }
              },
              {
                path: 'training-questions',
                name: 'BigModuleTrainingQuestions', //大模块训练
                component: () => import('@/views/english-big/english-big-training-questions.vue'),
                meta: { bigImg: true }
              },
              {
                path: 'training-questions-results',
                name: 'BigModuleTrainingQuestionsResults',
                component: () => import('@/views/english-big/english-big-training-questions-results.vue'),
                meta: { bigImg: true }
              },
              {
                path: 'big-start-effect-evaluation',
                name: 'BigModuleStartEffectEvaluation',
                component: () => import('@/views/english-big/english-big-start-effect-evaluation.vue'),
                meta: { bigImg: true }
              },
              {
                path: 'effect-evaluation',
                name: 'BigModuleEffectEvaluation',
                component: () => import('@/views/english-big/english-big-effect-evaluation.vue'),
                meta: { bigImg: true }
              },
              {
                path: 'effect-evaluation-end',
                name: 'BigModuleEffectEvaluationEnd',
                component: () => import('@/views/english-big/english-big-effect-evaluation-end.vue'),
                meta: { bigImg: true }
              },
              {
                path: 'effect-report',
                name: 'BigModuleEffectReport',
                component: () => import('@/views/english-big/english-big-effect-report.vue'),
                meta: { bigReportImg: true }
              }
            ]
          },
          // 大模拟
          {
            path: 'mock',
            name: 'Mock',
            redirect: 'mock/begin',
            component: () => import('../views/english-mock/index.vue'),
            children: [
              {
                path: 'begin',
                name: 'MockBegin',
                component: () => import('../views/english-mock/english-mock-list.vue'),
                meta: { mockImg: true }
              },
              {
                path: 'prompt',
                name: 'MockPrompt',
                component: () => import('../views/english-mock/english-mock-prompt.vue'),
                meta: { mockImg: true }
              },
              {
                path: 'listening',
                name: 'MockListening',
                component: () => import('../views/english-mock/english-mock-listening.vue'),
                meta: { mockImg: true }
              },
              {
                path: 'test',
                name: 'MockTest',
                component: () => import('../views/english-mock/english-mock-test.vue'),
                meta: { mockImg: true }
              },
              {
                path: 'test-end',
                name: 'MockTestEnd',
                component: () => import('../views/english-mock/english-mock-test-end.vue'),
                meta: { mockImg: true }
              },
              {
                path: 'report',
                name: 'MockReport',
                component: () => import('@/views/english-mock/english-mock-report.vue'),
                meta: { mockReportImg: true }
              }
            ]
          },
          //demo
          // {
          //   path: 'endpage',
          //   name: 'endpage',
          //   component: () => import('@/components/backgroung/end-test-background.vue'),
          // },
        ]
      },
      //闯关记录
      {
        path: 'ms3sprint/entry',
        name: 'Ms3sprintEntry',
        component: HomeLayout,
        redirect: '/ms3sprint/cache/entry/entryIndex',
        children: [
          {
            path: 'cache',
            name: 'cache',
            component: keepView,
            children: [
              {
                path: 'entryIndex',
                name: 'EntryIndex',
                // meta: { noHome: true },
                component: () => import('../views/english-entry/index.vue'),
              },
              {
                path: 'detail',
                name: 'detail',
                // meta: { entryAnswer: true },
                component: () => import('../views/english-entry/answer-details')
              },
              {
                path: 'log',
                name: 'log',
                meta: {
                  showLog: true
                },
                component: () => import('../views/english-entry/answer-details')
              },
              {
                path: 'complete',
                name: 'complete',
                mate: {
                  // showLog: true
                },
                component: () => import('../views/english-entry/answer-complete')
              }
            ]
          },

        ]
      },
      //体检报告
      {
        path: '/ms3sprint/medical',
        name: 'Ms3sprintMedical',
        component: HomeLayout,
        redirect: '/ms3sprint/medical/medicalIndex',
        
        children: [
          {
            path: 'medicalIndex',
            name: 'MedicalIndex',
            meta: { noHome: true },
            component: () => import('../views/english-medical/index.vue'),
          },
        ]
      },
      //家庭作业
      {
        path: '/ms3sprint/operation',
        name: 'Ms3sprintOperation',
        component: HomeLayout,
        redirect: '/ms3sprint/operation/operationIndex',
        children: [
          {
            path: 'operationIndex',
            name: 'OperationIndex',
            meta: { noHome: true },
            component: () => import('../views/english-homework/index.vue'),
          },
        ]
      },
      //微课视频
      {
        path: '/ms3sprint/video',
        name: 'Ms3sprintVideo',
        component: HomeLayout,
        redirect: '/ms3sprint/video/videoIndex',
        children: [
          {
            path: 'videoIndex',
            name: 'VideoIndex',
            // meta: { noHome: true },
            component: () => import('../views/english-video/index.vue'),
          },
        ]
      },
    ]
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  // ...
  next();
});

export default router;
