<template>
  <div class="header">
    <b-navbar toggleable="lg">
      <b-navbar-brand href="#">
        <img src="../../assets/images/logo.png" alt="" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav active>
          <b-nav-item> </b-nav-item>
          <b-nav-item href="#" :class="activeClass(1)" @click="navigation(1)">{{
            homeName()
          }}</b-nav-item>
          <b-nav-item href="#" :class="activeClass(2)" @click="navigation(2)"
            >闯关记录/错题本</b-nav-item
          >
          <b-nav-item href="#" :class="activeClass(3)" @click="navigation(3)"
            >体检报告</b-nav-item
          >
          <b-nav-item
            disabled
            href="#"
            :class="activeClass(4)"
            @click="navigation(4)"
            >家庭作业</b-nav-item
          >
          <b-nav-item href="#" disabled :class="activeClass(5)" @click="navigation(5)"
            >微课视频</b-nav-item
          >
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto header-right">
          <div class="personal-details">
            <div class="header-icon">
              <span class="iconfont icon-taolun-xian"></span>
              <!--  暂时取消 消息提示 -->
              <b-button
                @click="getMessageData"
                id="student-message"
                class="iconfont icon-xiaoxi3 xiaoxi3-button"
                ><div class="show-num-one" v-show="ShowNum"></div
              ></b-button>

              <b-popover
                custom-class="message-popover"
                target="student-message"
                triggers="focus"
                placement="bottom"
              >
                <div class="message-content explain-body">
                  <b-row
                    class="message-content-item"
                    v-for="(item) in messageData"
                    :key="item.notificationId"
                  >
                    <b-col cols="10">
                      <div v-if="item.type == 'App.EngSprintModuleSuccess'">
                        来自<span>{{ item["properties"].className }}</span
                        >的
                        <!-- .slice(0, 1) **-->
                        <span>{{ item["properties"].studentName }} </span>
                        同学 刚刚成功闯关
                        <span class="level-name">
                          {{
                            getModuleName(item["properties"].sprintModuleId)
                          }} </span
                        >，给ta点个赞吧
                      </div>
                      <div v-if="item.type == 'App.EngFundraisingUserLike'">
                        收到来自
                        <span>{{ item["properties"].studentName }}</span> 同学
                        <span class="level-name">给你点的赞</span>
                      </div>
                      <div class="information-time">
                        {{
                          item.creationTime
                            | dateFormat("YYYY 年 MM 月 DD 日   hh : mm")
                        }}
                      </div>
                    </b-col>
                    <b-col
                      v-if="item.type == 'App.EngSprintModuleSuccess'"
                      cols="2"
                      align-self="center"
                      @click="greatClick(item.notificationId, item)"
                    >
                      <span
                        class="iconfont icon-zan"
                        :class="item.isThumb ? 'color-zan' : ''"
                      ></span>
                    </b-col>
                  </b-row>
                </div>
              </b-popover>
              <span class="iconfont icon-w_baozhi"></span>
            </div>
            <b-container> </b-container>
            <img
              class="student-img"
              src="../../assets/images/icon-touxiangnan@2x.png"
              alt=""
            />
            <b-dropdown id="dropdown-1" class="m-md-2">
              <template #button-content>{{ userInfo.studentName }} </template>
              <b-row>
                <b-col cols="4" class="img-class">
                  <img
                    src="../../assets/images/icon-touxiangnan@2x.png"
                    alt=""
                  />
                </b-col>
                <b-col cols="8">
                  <b-dropdown-item
                    ><span class="student-name">{{
                      userInfo.studentName
                    }}</span>
                    <span class="link" @click="setPws"> 修改密码</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    >班级：{{ userInfo.className }}</b-dropdown-item
                  >
                  <b-dropdown-item
                    >学校：{{ userInfo.schoolName }}</b-dropdown-item
                  >
                  <b-dropdown-item>
                    <b-button
                      pill
                      variant="outline-danger"
                      @click="logOut"
                      class="logOut"
                    >
                      <span class="iconfont icon-dianyuan"></span> 退出
                    </b-button>
                  </b-dropdown-item>
                </b-col>
              </b-row>
            </b-dropdown>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <ResetPassword ref="reset"> </ResetPassword>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ResetPassword from "./components/ResetPassword.vue";
import { getNotification, saveThumb } from "@/api/index.js";
export default {
  data() {
    return {
      messageData: [],
      ShowNum: false,
    };
  },
  computed: {
    ...mapState(["userInfo", "currentModuleId"]),
  },
  components: {
    ResetPassword,
  },
  created() {
    this.$hub.$on("getNotification", this.getNotification);
  },
  methods: {
    greatClick(id, item) {
      if(item.isThumb) return
      saveThumb({ notificationId: id }).then((res) => {
        console.log("点赞成功", res);
        item.isThumb = true
      });
    },
    getMessageData() {
      this.ShowNum = false;
      this.changeSelectStyle = [];
      getNotification({}).then((res) => {
        console.log("同学动态", res);
        this.messageData = res.data.result.notifications;
      });
    },
    getNotification(data) {
      debugger;
      console.log("消息推送", data);
      this.ShowNum = true;
      if (data.notification.notificationName === "App.EngSprintModuleSuccess") {
        // this.getMessageData();
        this.AudioPlay(1);
      }
    },
    AudioPlay(state) {
      let audio = new Audio();
      if (state === 1) {
        audio.src = require("@/assets/audio/UI叮咚音效_同学动态-高品质.wav");
      }
      let playPromise = audio.play();
      // let tiemr = null;
      if (playPromise) {
        playPromise
          .then(() => {})
          .catch(() => {
            audio.play();
          });
      }
    },
    homeName() {
      // if (this.$route.meta.startImg || this.$route.meta.startReportImg) {
      //   return "战备体检";
      // }
      // if (this.$route.meta.smallImg || this.$route.meta.smallReportImg) {
      //   return "扫雷尖兵";
      // }
      // if (this.$route.meta.bigImg || this.$route.meta.bigReportImg) {
      //   return "沙漠行动";
      // }
      // if (this.$route.meta.mockImg || this.$route.meta.mockReportImg) {
      //   return "抢滩登陆";
      // }
      // return "闯关地图";
      if (
        this.currentModuleId == 1 &&
        !this.$route.meta.yesHome &&
        this.$route.name !== "EntryIndex" &&
        this.$route.name !== "VideoIndex" &&
        this.$route.name !== "MedicalIndex"
      ) {
        return "战备体检";
      }
      if (
        this.currentModuleId == 2 &&
        !this.$route.meta.yesHome &&
        this.$route.name !== "EntryIndex" &&
        this.$route.name !== "VideoIndex" &&
        this.$route.name !== "MedicalIndex"
      ) {
        return "扫雷尖兵";
      }
      if (
        this.currentModuleId == 4 &&
        !this.$route.meta.yesHome &&
        this.$route.name !== "EntryIndex" &&
        this.$route.name !== "VideoIndex" &&
        this.$route.name !== "MedicalIndex"
      ) {
        return "沙漠行动";
      }
      if (
        this.currentModuleId == 7 &&
        !this.$route.meta.yesHome &&
        this.$route.name !== "EntryIndex" &&
        this.$route.name !== "VideoIndex" &&
        this.$route.name !== "MedicalIndex"
      ) {
        return "抢滩登陆";
      }
      return "闯关地图";
    },
    //顶部导航栏
    navigation(id) {
      switch (id) {
        case 1:
          this.$router.push({ name: "HomeIndex" });
          break;
        case 2:
          this.$router.push({ name: "EntryIndex" });
          break;
        case 3:
          this.$router.push({ name: "MedicalIndex" });
          break;
        case 4:
          this.$router.push({ name: "OperationIndex" });
          break;
        case 5:
          this.$router.push({ name: "VideoIndex" });
          break;
      }
    },
    activeClass(id) {
      // console.log("id", id);
      // console.log("this.query.$route", this.$route.name);
      let route = this.$route.path;
      if (route.indexOf("ms3sprint/home") !== -1 && id == 1) {
        return "activeClass";
      }
      if (route.indexOf("ms3sprint/entry") !== -1 && id == 2) {
        return "activeClass";
      }
      if (route.indexOf("ms3sprint/medical") !== -1 && id == 3) {
        return "activeClass";
      }
      if (route.indexOf("ms3sprint/operation") !== -1 && id == 4) {
        return "activeClass";
      }
      if (route.indexOf("ms3sprint/video") !== -1 && id == 5) {
        return "activeClass";
      }
    },
    logOut() {
      sessionStorage.clear();
      // this.$router.replace({ name: "LoginLayout" });
      window.location.href = "https://peiliyou.com";
    },
    activeNavbar(id) {
      console.log("id", id);
    },
    setPws() {
      this.$refs.reset.open();
    },
  },
};
</script>

<style lang="scss" scoped>
.message-content {
  max-height: 31vh;
  // background-color: #2cb774;
  // padding: 10px 0;
  overflow-y: scroll;
  border-radius: 10px;
  .message-content-item {
    border-bottom: solid 1px #fff;
    padding: 5px 0;
    margin: 0;
  }
  .message-content-item:nth-child(odd) {
    background-color: #2cb774;
  }
  .message-content-item:nth-child(even) {
    background-color: #2cb774;
    opacity: 0.8;
  }
  .level-name {
    color: #fecc42;
  }
  .information-time {
    padding-top: 0.5rem;
  }
  .changeStyle {
    .icon-zan {
      color: #fecc42;
    }
  }
  .icon-zan {
    cursor: pointer;
  }
  .color-zan {
    color: #fecc42;
  }
}
.header {
  width: 100%;
  position: absolute;
  z-index: 999;
  letter-spacing: 1px;
  .navbar-nav {
    text-align: right;
  }
  .navbar {
    padding: 1rem 5vw;
  }
  .navbar-brand {
    flex-grow: 2;
    text-align: left;
    img {
      height: 2.3vw;
    }
  }
  .nav-item {
    // padding: 0 1vw;
    margin: 0 1.5vw;
    a {
      color: $text-light-gray;
      font-size: 1.05vw;
    }
  }
  .header-right {
    width: 22%;
    .student-img {
      width: 15%;
      padding-left: 1vw;
    }
  }
  .icon-taolun-xian {
    font-size: 1.09375vw;
  }
  .icon-xiaoxi3 {
    font-size: 1.302vw;
    font-weight: bold;
  }
  .icon-w_baozhi {
    font-size: 1.458vw;
    font-weight: bold;
  }
  .click-disable {
    pointer-events: none;
  }
  .personal-details {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .iconfont {
      padding: 0 0.5vw;
      color: rgba(0, 0, 0, 0.3);
      cursor: pointer;
    }
    .xiaoxi3-button {
      position: relative;
      padding-left: 0.5vw !important;
      color: #666 !important;
      .show-num-one {
        position: absolute;
        right: 11px;
        top: 3px;
        height: 10px;
        width: 10px;
        background-color: #e54646;
        border-radius: 50%;
        color: #fff;
        font-size: 12px;
        padding-bottom: 1px;
      }
    }
    .header-icon {
      display: flex;
      align-items: baseline;
    }
    .link {
      cursor: pointer;
      color: #fa4d3d;
      text-decoration: underline;
      padding-left: 0.5208333333333vw;
    }
    .img-class {
      display: flex;
      align-items: center;
    }
  }
  .logOut {
    color: #333;
    border-color: #dcdfe6;
    background-color: #fff;
    padding: 0.15rem 1.8rem !important;
    .icon-dianyuan:active {
      color: #2cb774;
    }
    .icon-dianyuan {
      padding: 0;
      // padding-left: 1.5rem;
    }
  }
  .activeClass {
    text-decoration: none;
    font-weight: bold;
    background-color: #2cb774;
    border-radius: 30px;
    display: flex;
    align-items: center;
    line-height: 1.2;
    a {
      color: #fff !important;
      padding: 0rem 1.2rem !important;
    }
  }
  .logOut:hover {
    color: #2cb774;
    background-color: #fff;
    border-color: #2cb774;
    .icon-dianyuan {
      color: #2cb774;
    }
  }
  .btn-outline-danger:not(:disabled):not(.disabled):active,
  .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #2cb774;
    background-color: #fff;
    border-color: #2cb774;
  }
  .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
  .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-danger.dropdown-toggle:focus {
    // box-shadow: ;
    box-shadow: none;
  }
}
</style>
<style lang="scss">
.header {
  .btn-secondary {
    background-color: transparent !important;
    border: none !important;
    color: $text-light-gray !important;
    font-size: 1.15rem !important;
  }
  .btn {
    padding-left: 0 !important;
  }
  .dropdown-menu {
    width: 25rem;
    left: -12vw;
    padding: 1.4rem 0px;
    img {
      width: 6vw;
      padding-left: 1rem;
    }
    .dropdown-item:hover,
    .dropdown-item:focus {
      background: none;
    }
  }
  .student-name {
    font-size: 1.2rem;
  }
  // .dropdown-item.active,
  // .dropdown-item:active {
  //   background-color: #fff;
  // }
  .icon-xiaoxi3:before {
    font-size: 1.7rem;
  }
  .icon-xiaoxi3:not(:disabled):not(.disabled):active:focus,
  .icon-xiaoxi3:focus {
    box-shadow: none;
  }
}
.personal-details {
  .dropdown {
    margin: 0 !important;
  }
}
.message-popover {
  max-width: 305px !important;
  border-radius: 10px !important;
  .popover-body {
    color: #fff;
    line-height: 1.5;
    padding: 0;
  }
  .arrow {
    border-bottom-color: #2cb774;
  }

  div.arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #2cb774;
  }
}
</style>