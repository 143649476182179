import { mapGetters } from 'vuex'
import moment from "moment";
export default {
    computed: {
        ...mapGetters([
            'listOfWrongQuestions',
            'redoQuestion',
            'examId',
            'currentModuleId',
            'userId',
            'userInfo',
            'logDetail'
        ])
    },
    methods: {
        getQuestionType(id) {
            let name = "";
            switch (parseInt(id)) {
                case 1:
                    name = "听力测试";
                    break;
                case 2:
                    name = "选择填空";
                    break;
                case 3:
                    name = "完形填空";
                    break;
                case 4:
                    name = "补全对话";
                    break;
                case 5:
                    name = "阅读理解";
                    break;
                case 6:
                    name = "语法填空";
                    break;
                case 7:
                    name = "阅读理解七选五";
                    break;
                case 8:
                    name = "书面表达";
                    break;
                case 9:
                    name = "听力A";
                    break;
                case 10:
                    name = "听力B";
                    break;
                case 11:
                    name = "听力C";
                    break;
                case 12:
                    name = "听力D";
                    break;
                case 13:
                    name = "阅读理解A";
                    break;
                case 14:
                    name = "阅读理解B";
                    break;
                case 15:
                    name = "阅读理解C";
                    break;
                case 16:
                    name = "阅读理解D";
                    break;
                case 17:
                    name = "语法填空A";
                    break;
                case 18:
                    name = "语法填空B";
                    break;
                case 19:
                    name = "情景运用";
                    break;
                case 20:
                    name = "小作文";
                    break;
                case 21:
                    name = "选择题";
                    break;
                case 22:
                    name = "填空题";
                    break;
                case 23:
                    name = "翻译";
                    break;
                case 24:
                    name = "写作训练";
                    break;
            }
            return name
        },
        getDiffcaultName(id) {
            let name = "";
            switch (parseInt(id)) {
                case 7:
                    name = "七年级";
                    break;
                case 8:
                    name = "八年级";
                    break;
                case 9:
                    name = "九年级";
                    break;
            }
            return name;
        },
        getModuleName(id) {
            let name = ""
            switch (parseInt(id)) {
                case 1:
                    name = "战备体检";
                    break;
                case 2:
                    name = "扫雷尖兵";
                    break;
                case 4:
                    name = "沙漠行动";
                    break;
                case 7:
                    name = "抢滩登陆";
                    break;
            }
            return name
        },
        getOldAnswers(type, questionId) {
            //type  0测评试卷 1大模块训练 2小模块训练
            if (!sessionStorage.getItem('userAnswers')) {
                return
            }
            let userAnswers = JSON.parse(sessionStorage.getItem('userAnswers'))
            if (type === 0) {
                for (let a of userAnswers['answerSectionInputs']) {
                    for (let b of a.answerQuestionInputs) {
                        if (b.questionId === questionId) {


                            return b.answer
                        }
                    }
                }
            }
            if (type === 1) {
                for (let c of userAnswers) {
                    if (c.questionId === questionId) {
                        return c.answer
                    }
                }
            }
        },
        getOldInputAnswer() {
            const oldAnswers = JSON.parse(sessionStorage.getItem("values"));
            for (const key in oldAnswers) {
                const h = document
                    .getElementsByClassName(key)[0]
                    .getElementsByTagName("input");
                h.forEach((elem, index) => {
                    elem.value = oldAnswers[key][index];
                }); 
            }
        },
        getOldwritingAnswers(type) {
            if (type === 1) {
                if (!sessionStorage.getItem('userAnswers')) {
                    return
                }
                let userAnswers = JSON.parse(sessionStorage.getItem('userAnswers'))
                for (let a of userAnswers) {
                    if (document.getElementById(a.questionId)) {
                        document.getElementById(a.questionId).value = a.answer;
                    }
                }
                return
            }
            for (let a of this.userAnswers.answerSectionInputs) {
                if (a.sectionType === 8) {
                    for (let b of a.answerQuestionInputs) {
                        let textEl = document.getElementById(b.questionId)
                        if (textEl) {
                            textEl.value = b.answer;
                            textEl.placeholder = b.answer;
                        }
                    }
                    break
                }
            }
        },
        replaceAnalysis(type) {
            if (type) {
                let reg = /(\w*)<a(.*)a>(.*)/g;
                console.log('reg',reg)
                return type.replace(reg, "$1<span$2span>$3");
            } else {
                return ""
            }

        },
        renderLaber(isRedo, isPractice, timer) {
            let srt = isRedo &&  isPractice? "重做  " : isPractice ? "自由  " : "闯关  ";
            return srt + moment(timer).format("YYYY/MM/DD HH:mm:ss");
          },
    }
}
