<template>
  <div id="app">
    <router-view />
    <b-modal v-model="modalShow">
      <div class="d-block text-center">
        <h3>Hello From My Modal!</h3>
      </div>
      <b-button class="mt-3" variant="outline-danger" block @click="hideModal"
        >Close Me</b-button 
      >
    </b-modal>
  </div>
</template>
<script>
export default {
  data(){
    return {
      modalShow: false
    }
  },
  created() {
    //连接sinaglr
    // var token = sessionStorage.getItem("userToken");
    // this.startSignalR(token);
  },
  mounted() {
    let setTimerFn = ''
    setTimerFn = time => {
      let timer = setTimeout(() => {
        if (this.$route.path == "/login") return;
        this.count++;
        let interVal;
        if (this.count == 60) {
          this.dialogVisible = true;
          if (interVal) return;
          interVal = setInterval(
            (() => {
              if (this.num <= 0) {
                clearTimeout(timer);
                clearInterval(interVal);
                this.dialogVisible = false;
                this.outIn();
                this.count = 0;
                return this.$confirm(
                  "系统检测到您已经很久没有活动过了",
                  "提示",
                  {
                    confirmButtonText: "重新登录",
                    type: "warning",
                    showCancelButton: false
                  }
                )
                  .then(() => {
                    clearInterval(interVal);
                    this.count = 0;
                    this.num = 60;
                    this.outIn();
                    // window.location = window.location;
                  })
                  .catch(err => console.log(err));
              }
              this.num--;
            }).bind(this),
            1000
          );
        }
        setTimerFn(time);
      }, time);
    };
    // let timer = setTimerFn(1000 * 60);
  },
  methods: {
    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
};
</script>
<style lang="scss">
@import url('../public/RobotoFonts/roboto.css');
#app {
  height: 100%;
  font-family: 'Roboto', Avenir,  Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: $text-light-gray;
  background-color: $bg-gray;
  letter-spacing: 1px;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.fill-blank{
  border: none;
  border-bottom: 1px solid ;
  text-align: center;
  height: 21px;
  background-color: rgba(127, 255, 170, 0.2);
}
.fill-blank-readonly {
  width: 80px;
  border: none;
  border-bottom: 1px solid;
  background: none;
}
/*滚动条样式*/
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 6px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #dcdcdc;
}
.box-wrap.el-scrollbar__wrap::-webkit-scrollbar {
  display: none !important;
  /*滚动条整体样式*/
  width: 0px !important;
  height: 0px !important;
}
</style>
