<template>
  <div style="height: 100%; position: relative">
    <common-header></common-header>
    <AnswerPublicBackground>
      <my-keep-view></my-keep-view>
    </AnswerPublicBackground>
  </div>
</template>

<script>
import CommonHeader from "@/views/english-header/header";
import AnswerPublicBackground from "@/components/backgroung/answer-public-background";
import MyKeepView from "../keepView";

export default {
  components: {
    CommonHeader,
    AnswerPublicBackground,
    MyKeepView
  },
};
</script>

<style lang="scss" scoped>
</style>
