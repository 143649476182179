//https://www.dotnetcurry.com/aspnet-core/1480/aspnet-core-vuejs-signalr-app
//https://github.com/DaniJG/so-signalr

import { HubConnectionBuilder, LogLevel } from "@aspnet/signalr";

export default {
  install(Vue) {
    // use a new Vue instance as the interface for Vue components to receive/send SignalR events
    // this way every component can listen to events or send new events using this.$hub
    const abpCommonHub = new Vue();
    Vue.prototype.$hub = abpCommonHub;

    // Provide methods to connect/disconnect from the SignalR hub
    let connection = null;
    let startedPromise = null;
    let manuallyClosed = false;
    let baseUrl = process.env.VUE_APP_API_BASE_URL;
    let signalrUrl = (baseUrl || "").replace(/\/$/, "") + "/signalr";
    Vue.prototype.startSignalR = (jwtToken) => {
      console.log("try to connect SignalR");

      connection = new HubConnectionBuilder()
        .withUrl(
          signalrUrl,
          jwtToken ? { accessTokenFactory: () => jwtToken } : {}
        )
        .configureLogging(LogLevel.Information)
        .build();

      // Forward hub events through the event, so we can listen for them in the Vue components
      connection.on("getNotification", (notification) => {
        abpCommonHub.$emit("getNotification", notification);
      });

      // You need to call connection.start() to establish the connection but the client wont handle reconnecting for you!
      // Docs recommend listening onclose and handling it there.
      // This is the simplest of the strategies
      function start() {
        console.log('启动检测')
        startedPromise = connection.start().catch((err) => {
          console.error("Failed to connect with hub", err);
          return new Promise((resolve, reject) =>
            setTimeout(
              () =>
                start()
                  .then(resolve)
                  .catch(reject),
              36000
            )
          );
        });
        return startedPromise;
      }

      connection.onclose(() => {
        if (!manuallyClosed) start();
      });

      // Start everything
      manuallyClosed = false;
      start();
    };

    Vue.prototype.stopSignalR = () => {
      if (!startedPromise) return;

      manuallyClosed = true;
      return startedPromise
        .then(() => connection.stop())
        .then(() => {
          startedPromise = null;
        });
    };

    // Provide methods for components to send messages back to server
    // Make sure no invocation happens until the connection is established
    //hub.questionOpened = (questionId) => {
    //    if (!startedPromise) return

    //    return startedPromise
    //        .then(() => connection.invoke('JoinQuestionGroup', questionId))
    //        .catch(console.error)
    //}
    //hub.questionClosed = (questionId) => {
    //    if (!startedPromise) return

    //    return startedPromise
    //        .then(() => connection.invoke('LeaveQuestionGroup', questionId))
    //        .catch(console.error)
    //}
    //hub.sendMessage = (message) => {
    //    if (!startedPromise) return

    //    return startedPromise
    //        .then(() => connection.invoke('SendLiveChatMessage', message))
    //        .catch(console.error)
    //}
  }
};
