<template>
  <keep-alive :include="['logList', 'entryIndex']" :exclude="['big']">
    <router-view></router-view>
  </keep-alive>
</template>

<script>
  export default {
    name: "MyKeepView"
  }
</script>

<style lang="scss" scoped>


</style>
