<template>
  <div class="global-back">
    <!-- 首页闯关地图背景 -->
    <div style="height: 100%" v-if="$route.meta.yesHome">
      <div class="back-decoration-top"></div>
      <div class="back-decoration-bottom"></div>
      <slot></slot>
    </div>
    <!-- 首页导航栏除闯关地图外的背景 -->
    <div style="height: 100%" v-if="$route.meta.noHome">
      <slot></slot>
    </div>
    <!-- 四个模块答题页背景 -->
    <div
      v-if="!$route.meta.noHome && !$route.meta.yesHome"
      :class="ModuleBack()"
    >
      <div class="module-test">{{ moduleTest }}</div>
      <div class="module-testB">{{ moduleTestB }}</div>
      <div></div>
      <div class="back-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState} from "vuex";
export default {
  data() {
    return {
      moduleTest: "",
      moduleTestB: "",
    };
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        // debugger;
        // console.log(this.$route, 11111);
        this.ModuleBack();
      },
    },
  },
  mounted() {
    this.ModuleBack();
  },
   computed: {
    ...mapState([
      "currentModuleId",
    ]),
  },
  methods: {
    ModuleBack() {
      // 0 战备体检； 1 小模块； 2 大模块； 3 大模拟；
      // 4 战备体检报告页面背景； 5 小模块模拟体检报告页面背景； 6 大模块体检报告页面背景； 7 大模拟体检报告页面背景；
      console.log("this.$route.query.module");
      if (this.currentModuleId == 1 && !this.$route.meta.startReportImg) {
        this.moduleTest = "战备体检";
        this.moduleTestB = "定级测评";
        return "examination-back module-character";
      }
      if (this.currentModuleId == 2 && !this.$route.meta.smallReportImg) {
        this.moduleTest = "扫雷尖兵";
        this.moduleTestB = "小模块冲刺";
        return "small-back module-character";
      }
      if (this.currentModuleId == 4 && !this.$route.meta.bigReportImg) {
        this.moduleTest = "沙漠行动";
        this.moduleTestB = "大模块冲刺";
        return "big-back module-character";
      }
      if (this.currentModuleId == 7 && !this.$route.meta.mockReportImg) {
        this.moduleTest = "抢滩登陆";
        this.moduleTestB = "大模拟";
        return "main-back module-character";
      }
      if (this.currentModuleId == 1 && this.$route.meta.startReportImg) {
        this.moduleTest = "";
        this.moduleTestB = "";
        return "examination-report-bg module-character";
      }
      if (this.currentModuleId == 2 && this.$route.meta.smallReportImg) {
        this.moduleTest = "";
        this.moduleTestB = "";
        return "small-report-bg module-character";
      }
      if (this.currentModuleId == 4 && this.$route.meta.bigReportImg) {
        this.moduleTest = "";
        this.moduleTestB = "";
        return "big-report-bg module-character";
      }
      if (this.currentModuleId == 7 && this.$route.meta.mockReportImg) {
        this.moduleTest = "";
        this.moduleTestB = "";
        return "mock-report-bg module-character";
      }
      return "main-back";
    },
    // characterDisplay() {
    //   if (
    //     this.$route.query == "report" ||
    //     this.$route.query == "SmallModuleGrammarTestEnd" ||
    //     this.$route.query == "BigModuleReport" ||
    //     this.$route.query == "MockReport"
    //   ) {
    //     return false;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.global-back {
  height: 100%;
  position: relative;
}
// 战备体检背景样式
.examination-back {
  min-height: 100%;
  background-image: url("../../assets/images/index_pic27.png"),
    url("../../assets/images/index_pic28.png");
  background-position: top, bottom 0 left 3%;
  background-repeat: no-repeat, no-repeat;
  background-size: 100%, 100%;
  background-color: #f9e2da;
  .module-test {
    position: absolute;
    top: 5.7vw;
    right: 22%;
    color: #fff;
    font-size: 0.9vw;
  }
  .module-testB {
    position: absolute;
    top: 8vw;
    right: 23.3%;
    color: #fff;
    font-size: 0.7vw;
  }
}
.examination-report-bg {
  min-height: 100%;
  background-image: url("../../assets/images/examination-report-bg.png"),
    url("../../assets/images/index_pic28.png");
  background-position: top, bottom 0 left 3%;
  background-repeat: no-repeat, no-repeat;
  background-size: 100%, 100%;
  background-color: #f9e2da;
}
//小模块背景样式
.small-back {
  min-height: 100%;
  background-image: url("../../assets/images/index_pic25.png"),
    url("../../assets/images/index_pic26.png");
  background-position: top, bottom 0 left 3%;
  background-repeat: no-repeat, no-repeat;
  background-size: 100%, 100%;
  background-color: #cbf5f7;
  .module-test {
    position: absolute;
    top: 5.7vw;
    right: 21.8%;
    color: #fff;
    font-size: 0.9vw;
  }
  .module-testB {
    position: absolute;
    top: 8vw;
    right: 22.6%;
    color: #fff;
    font-size: 0.7vw;
  }
}
.small-report-bg {
  min-height: 100%;
  background-image: url("../../assets/images/small-report-bg.png"),
    url("../../assets/images/index_pic26.png");
  background-position: top, bottom 0 left 3%;
  background-repeat: no-repeat, no-repeat;
  background-size: 100%, 100%;
  background-color: #cbf5f7;
}
//大模块背景样式
.big-back {
  min-height: 100%;
  background-image: url("../../assets/images/index_pic29.png"),
    url("../../assets/images/index_pic30.png");
  background-position: top, bottom 0 left 3%;
  background-repeat: no-repeat, no-repeat;
  background-size: 100%, 100%;
  background-color: #feefd8;
  .module-test {
    position: absolute;
    top: 5.7vw;
    right: 21.8%;
    color: #fff;
    font-size: 0.9vw;
  }
  .module-testB {
    position: absolute;
    top: 8vw;
    right: 22.6%;
    color: #fff;
    font-size: 0.7vw;
  }
}
.big-report-bg {
  min-height: 100%;
  background-image: url("../../assets/images/big-report-bg.png"),
    url("../../assets/images/index_pic30.png");
  background-position: top, bottom 0 left 3%;
  background-repeat: no-repeat, no-repeat;
  background-size: 100%, 100%;
  background-color: #feefd8;
}
// 大模拟背景样式
.main-back {
  min-height: 100%;
  background-image: url("../../assets/images/index_pic31.png"),
    url("../../assets/images/index_pic32.png");
  background-position: top, bottom 0 left 3%;
  background-repeat: no-repeat, no-repeat;
  background-size: 100%, 100%;
  background-color: #cbf9ef;
  .module-test {
    position: absolute;
    top: 5.7vw;
    right: 22%;
    color: #fff;
    font-size: 0.9vw;
  }
  .module-testB {
    position: absolute;
    top: 8vw;
    right: 24%;
    color: #fff;
    font-size: 0.7vw;
  }
}
.mock-report-bg {
  min-height: 100%;
  background-image: url("../../assets/images/mock-report-bg.png"),
    url("../../assets/images/index_pic32.png");
  background-position: top, bottom 0 left 3%;
  background-repeat: no-repeat, no-repeat;
  background-size: 100%, 100%;
  background-color: #cbf9ef;
}
.back-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vw;
  padding-bottom: 10vw;
  height: 100%;
}
.back-decoration-top {
  position: absolute;
  top: 0;
  right: 64vw;
  width: 300px;
  height: 260px;
  background: $bg-dark-gray;
  opacity: 0.6;
  border-radius: 0 0 40px 50px;
  transform: skewX(25deg);
}
.back-decoration-bottom {
  position: absolute;
  bottom: 0;
  left: 48vw;
  width: 21vw;
  height: 151px;
  background: #ddd;
  opacity: 0.61;
  border-radius: 25px 43px 0 0;
  transform: skewX(34deg);
}
</style>
