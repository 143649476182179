// import Vue from "vue";
import moment from "moment";

export default function(Vue) {
  Vue.filter("dateFormat", function(
    dataStr,
    pattern= "YYYY-MM-DD"
  ) {
    return moment(dataStr).format(pattern);
  });
  Vue.filter("turnPercentage", function(num) {
    if (!num) return 0;
    num = Math.round(num * 100);
    return Number(num);
  });
}
