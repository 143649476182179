<template>
  <div class="changePassword">
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="460px"
      height="400px"
      :center="true"
      :destroy-on-close="true"
      :modal="false"
    >
      <div>
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          status-icon
          :rules="rules"
          label-width="100px"
        >
          <el-form-item label="旧密码" prop="currentPass">
            <el-input
              v-model.trim="ruleForm.currentPass"
              show-password
              autocomplete="off"
              placeholder="请输入旧密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="pass">
            <el-input
              v-model.trim="ruleForm.pass"
              show-password
              autocomplete="off"
              placeholder="请输入新密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input
              v-model.trim="ruleForm.checkPass"
              show-password
              autocomplete="off"
              placeholder="请再次输入新密码"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { ChangePassword } from "@/api/index";
export default {
  name: "ResetPassword",
  data: function() {
    let validatCurrentPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("旧密码不能为空"));
      }
      callback();
    };
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value === this.ruleForm.currentPass) {
        callback(new Error("新密码不能与旧密码相同"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      show: false,
      dialogVisible: false,
      ruleForm: {
        currentPass: "",
        pass: "",
        checkPass: ""
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        currentPass: [{ validator: validatCurrentPass, trigger: "blur" }]
      }
    };
  },
  computed: {
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          ChangePassword({
            currentPassword: this.ruleForm.currentPass,
            newPassword: this.ruleForm.checkPass
          })
            .then(res => {
              if (res.success === true) {
                this.$message({
                  message: "密码修改成功！请牢记新密码",
                  type: "success"
                });
              } else {
                this.$message.error("密码修改失败，请输入正确的旧密码！");
              }
            })
            .catch(() => {
              this.$message.error("密码修改失败，请输入正确的旧密码！");
            });
          this.dialogVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    open() {
      this.ruleForm = {
        currentPass: "",
        pass: "",
        checkPass: ""
      };
      this.dialogVisible = true;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style lang="scss">
.changePassword {
  .el-dialog {
    height: 373px !important;
    border-radius: 10px;
  }
  .el-dialog::before {
    content: "";
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
  .close-wrap {
    right: -35px !important;
    top: -35px !important;
    width: 30px !important;
    height: 30px !important;
  }
  .el-dialog .el-dialog__header {
    padding-top: 15px !important;
    span {
      font-weight: bold;
      color: #333;
    }
  }
  .el-dialog--center .el-dialog__body {
    padding: 25px 20px 12px;
  }
  .el-input__inner {
    width: 85% !important;
  }
  .el-input__inner {
    border: 1px solid $border-green;
  }
  .el-dialog .el-icon-close:before {
    color: #000;
  }
  .el-dialog__headerbtn .el-dialog__close {
    font-weight: bold;
  }
}
</style>
