import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mixins from "@/mixins/index.js";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// 导入 Bootstrap 和 BootstrapVue CSS 文件（顺序很重要）
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/theme/index.css";
import "./utils/dragDIalog";
import filter from "./filters/index";
import directiveInit from "../src/directives/index";
import Print from "vue-print-nb";
import Hub from "./plugin/signalr.js";
directiveInit(Vue);
filter(Vue);
// import './permission'
import {
  Tabs,
  TabPane,
  Row,
  Col,
  Checkbox,
  CheckboxGroup,
  Select,
  Button,
  Option,
  Table,
  TableColumn,
  Pagination,
  Dialog,
  Form,
  Input,
  FormItem,
  Image,
  Tooltip,
  Popover,
  Menu,
  Submenu,
  MenuItem,
} from "element-ui";
import htmlToPdf from "@/utils/htmlToPdf";

// 使用Vue.use()方法就会调用工具方法中的install方法
Vue.use(htmlToPdf);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(Menu);
Vue.use(Tooltip);
Vue.use(Pagination);
Vue.use(TableColumn);
Vue.use(Table);
Vue.use(Option);
Vue.use(Button);
Vue.use(Select);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Row);
Vue.use(Col);
Vue.config.productionTip = false;
Vue.use(TabPane);
Vue.use(Tabs);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(Input);
Vue.use(FormItem);
Vue.use(Image);
Vue.use(Popover);
// 使 BootstrapVue 在整个项目中可用
Vue.use(BootstrapVue);
// 可选择安装 BootstrapVue 图标组件插件
Vue.use(IconsPlugin);
Vue.mixin(mixins);
// 调取浏览器打印
Vue.use(Print);
// signalr
Vue.use(Hub);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
router.beforeEach((to, from, next) => {
  const isLogin =
    sessionStorage.userToken != "" && sessionStorage.userToken != null;
  if (to.path == "/login") {
    next();
  } else {
    if (isLogin) {
      // 登陆过
      next();
    } else {
      next("/login");
    }
  }
});
//使用Vue.directive()定义一个全局指令
//1.参数一：指令的名称，定义时指令前面不需要写v-
//2.参数二：是一个对象，该对象中有相关的操作函数
//3.在调用的时候必须写v-
const drag = Vue.directive("drag", {
  //1.指令绑定到元素上回立刻执行bind函数，只执行一次
  //2.每个函数中第一个参数永远是el，表示绑定指令的元素，el参数是原生js对象
  //3.通过el.focus()是无法获取焦点的，因为只有插入DOM后才生效
  bind: function() {},
  //inserted表示一个元素，插入到DOM中会执行inserted函数，只触发一次
  inserted: function(el) {
    el.onmousedown = function(e) {
      var disx = e.pageX - el.offsetLeft;
      var disy = e.pageY - el.offsetTop;
      document.onmousemove = function(e) {
        el.style.left = e.pageX - disx + "px";
        el.style.top = e.pageY - disy + "px";
      };
      document.onmouseup = function() {
        document.onmousemove = document.onmouseup = null;
      };
    };
  },
  //当VNode更新的时候会执行updated，可以触发多次
  // updated: function (el) { }
});
export default drag;
