import Vue from 'vue'
import Vuex from 'vuex'
import {getSessionStorage, setSessionStorage} from "../utils/storage";
import { getUserProducts} from "@/api/english-mock";
import getters from "./getters";
Vue.use(Vuex)
function randomString() {
  setRedoTime()
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
function  setRedoTime() {
 sessionStorage.setItem('redoTime', +new Date())
}
const getDefaultState = () => {
  return {
    token: null,
    userInfo: null,
    enterOrderData: null,
    userId:  null,
    loginData: null,
    homeSildeIndex: 0,
    userCourseAndOrders: null,
    listeningTime: 0,
    listeningAnswers: null,
    simulationExamQuestions: null,
    testEndTime: null,
    userAnswers: null,
    trainingData:null,
    trainingTest:null,
    trainingBeginTime:null,
    userCorrectAnswer: [],
    mockTestName:null,
    listOfWrongQuestions: [],
    redoQuestion: {},
    examId: '',
    logDetail: {},
    currentModuleId: 1, // 適用於當前頁面活動關卡， 并不是真的用戶進程關卡
    permission:[],
    trainingAnswer:null,
    questionNumber: 1,
    // addressImg:null
  }
}

let  state =  getDefaultState()
let keys = Object.keys(state)
keys.forEach(key => {
  state[key] = getSessionStorage(key, state[key])
})

const store = new Vuex.Store({
  state,
  getters,
  mutations: {
    // setAddressImg(state, addressImg) {
    //   state.addressImg = addressImg
    //   setSessionStorage('addressImg', addressImg)
    // },
    setTrainingAnswer(state, trainingAnswer) {
      state.trainingAnswer = trainingAnswer
      setSessionStorage('trainingAnswer', trainingAnswer)
    },
    setPermission(state, permission) {
      state.permission = permission
      setSessionStorage('permission', permission)
    },
    setCurrentModuleId(state, currentModuleId) {
      console.info(currentModuleId)
      state.currentModuleId = currentModuleId
      setSessionStorage('currentModuleId', currentModuleId)
    },
    setLogDetail(state, data) {
      state.logDetail = data
      setSessionStorage('logDetail', data)
    },
    setTrainingBeginTime (state, trainingBeginTime) {
      state.trainingBeginTime = trainingBeginTime
      setSessionStorage('trainingBeginTime', trainingBeginTime)
    },
    setUserAnswers (state, userAnswers) {
      state.userAnswers = userAnswers
      setSessionStorage('userAnswers', userAnswers)
    },
    setTestEndTime (state, testEndTime) {
      state.testEndTime = testEndTime
      setSessionStorage('testEndTime', testEndTime)
    },
    setSimulationExamQuestions (state, simulationExamQuestions) {
      state.simulationExamQuestions = simulationExamQuestions
      setSessionStorage('simulationExamQuestions', simulationExamQuestions)
    },
    setListeningAnswers (state, listeningAnswers) {
      state.listeningAnswers = listeningAnswers
      setSessionStorage('listeningAnswers', listeningAnswers)
    },
    setListeningTime (state, listeningTime) {
      state.listeningTime = listeningTime
      setSessionStorage('listeningTime', listeningTime)
    },
    setUserCourseAndOrders (state, userCourseAndOrders) {
      state.userCourseAndOrders = userCourseAndOrders
      setSessionStorage(userCourseAndOrders)
    },
    setHomeSildeIndex (state, homeSildeIndex) {
      state.homeSildeIndex = homeSildeIndex
      setSessionStorage('homeSildeIndex', homeSildeIndex)
    },
    setLoginData (state, loginData) {
      if (loginData.studentName === loginData.phoneNumber) {
        const newStr = loginData.studentName.substring(0, 3) + '****' + loginData.studentName.substring(7, loginData.studentName.length)
        loginData.studentName = newStr
      }
      state.loginData = loginData
      setSessionStorage('loginData', loginData)
    },
    setUserToken (state, userToken) {
      state.userToken = userToken
      setSessionStorage('userToken', userToken)
    },
    setUserInfor (state, userInfo) {
      state.userInfo = userInfo
      setSessionStorage('userInfo', userInfo)
    },
    setEnterOrderData (state, enterOrderData) {
      state.enterOrderData = enterOrderData
      setSessionStorage('enterOrderData', enterOrderData)

    },
    setUserId (state, userId) {
      state.userId = userId
      setSessionStorage('userId', userId)
    },
    setTrainingData(state, trainingData) {
      state.trainingData = trainingData
      setSessionStorage('trainingData', trainingData)
    },
    setTrainingTest(state, trainingTest) {
      state.trainingTest = trainingTest
      setSessionStorage('trainingTest', trainingTest)
    },
    //用户的正确答案
    setUserCorrectAnswer(state, userCorrectAnswer) {
      state.userCorrectAnswer = userCorrectAnswer
      setSessionStorage('userCorrectAnswer', userCorrectAnswer)
    },
    setMockTestName(state, mockTestName){
      state.mockTestName = mockTestName
      setSessionStorage('mockTestName', mockTestName)
    },
    setListOfWrongQuestions(state, listOfWrongQuestions){
      debugger
      state.listOfWrongQuestions = listOfWrongQuestions
      state.redoQuestion = listOfWrongQuestions[0]
      setSessionStorage('redoQuestion', listOfWrongQuestions[0])

      setSessionStorage('listOfWrongQuestions', listOfWrongQuestions)
    },
    setredoQuestion(state, redoQuestion){
      state.redoQuestion = redoQuestion
      setSessionStorage('redoQuestion', redoQuestion)
    },
    setExamId(state){
      state.examId = randomString()
      state.questionNumber = 1
      setSessionStorage('questionNumber',  state.questionNumber)
      setSessionStorage('examId', state.examId)
    },
    setQuestionNumber(state, questionNumber) {
      state.questionNumber = questionNumber
      setSessionStorage('questionNumber',  state.questionNumber)
    }
  },
  actions: {
    completeredoQuestion({commit, state}) {
      state.listOfWrongQuestions.shift()
      commit('setListOfWrongQuestions', state.listOfWrongQuestions)
    },
    pushQuestion({commit, state}, question ) {
      commit('setListOfWrongQuestions', state.listOfWrongQuestions.push(question))

    },
    setTestTime (context, value) {
      context.commit('SET_TEST_TIME', value)
    },
    getInfo({ commit }) {
      return new Promise(  (resolve, reject) => {
         getUserProducts().then(res => {
          console.log("测试跳转获取用户信息", res);
          if (
              !res.data.result.productsByUserPermissions ||
              res.data.result.productsByUserPermissions.length <= 0
          ) {
            this.$message.error("你的产品权限尚未开通，请联系培立优客服!");
            return reject(false);
          }
          if (res.data.result.productsByUserPermissions[0]) {
            commit("setProduct", res.data.result.productsByUserPermissions[0]);
            commit("setPermission", res.data.result.userPermissionNames);
            //不要轻易改变，此处刷新是为了解决一些页面缓存问题
            reject()
            window.location.href = `${window.location.origin}/ms3sprint/home/homeIndex`;
          }
        });

      })
    }
  }

})
export default store
